var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"button",attrs:{"title":_vm.title,"to":_vm.path}},[(_vm.icon.length > 0 && _vm.icon.startsWith('fa'))?_c('span',{staticClass:"icon is-small"},[_c('i',{class:'fa ' + _vm.icon})]):_vm._e(),(_vm.icon === 'plus')?_c('plus-icon',{class:{
      icon: true,
      'is-small': true,
      'only-icon': !_vm.isText
    }}):_vm._e(),(_vm.icon === 'download')?_c('download-icon',{class:{
      icon: true,
      'is-small': true,
      'only-icon': !_vm.isText
    }}):_vm._e(),(_vm.icon === 'upload')?_c('upload-icon',{class:{
      icon: true,
      'is-small': true,
      'only-icon': !_vm.isText
    }}):_vm._e(),(_vm.icon === 'edit')?_c('edit-icon',{class:{
      icon: true,
      'is-small': true,
      'only-icon': !_vm.isText
    }}):_vm._e(),(_vm.icon === 'delete')?_c('trash-icon',{class:{
      icon: true,
      'is-small': true,
      'only-icon': !_vm.isText
    }}):_vm._e(),(_vm.icon === 'restore')?_c('rotate-ccw-icon',{class:{
      icon: true,
      'is-small': true,
      'only-icon': !_vm.isText
    }}):_vm._e(),(_vm.isText)?_c('span',{class:{
      text: true,
      'is-hidden-touch': _vm.isResponsive
    }},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }